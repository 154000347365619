@import '_text', '_colors', '_rwd.scss', '_breakpoints.scss';

ul,
li,
p,
html,
body {
    margin: 0;
    padding: 0;
}

html,
body,
#__next {
    height: 100%;
}

#modalroot {
    height: auto;

    @media screen and (min-width: 1200px) {
        height: 100%;
    }
    @media screen and (max-height: 720px) {
        height: auto;
    }
}

body {
    font-family: 'Poppins';

    // prevent content scrolling when modal is open
    &.ReactModal__Body--open {
        overflow: hidden;
    }
}

input {
    @include p;

    font-size: 16px !important;
    border: 1px solid $base50;
    border-radius: 0.25rem;
    outline: 0;
}

h1,
h2 {
    margin: 0;
}

ul {
    list-style-type: none;
}

ul li {
    display: block;
}

footer {
    display: block;
}
